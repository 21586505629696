
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  EventsContainer,
  MainEventsContainer,
  CompletedChallengeWrapper,
} from "../MainEvents/styles";
import { NoComments } from "../SocialFeeds/styles";
import { withTranslation } from "react-i18next";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import { connect } from "react-redux";
import SkeletonLoder from "../common/skeletonLoder";
import SingleEvent from "../MainEvents/SingleEventV2";

class CompletedEvents extends Component {
  constructor() {
    super();
    this.state = {
      pageNumber: 1,
      eventCreate: false,
      ondemandCreate: false,
      scheduledEvents: false
    };
  }

  componentDidMount() {
  }

  onScroll = (e, events) => {
    const { pageNumber } = this.state;
    const { loadMoreEvents, totalCount } = this.props;
    const bottom = Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)) ===Math.round(e.target.clientHeight+1)||Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)+1) ===Math.round(e.target.clientHeight+1);
    if (events.length < totalCount) {
      // console.log("if events.length < totalCount", events.length < totalCount)
      if (bottom) {
        this.setState(
          {
            pageNumber: pageNumber + 1,
          },
          () => {
            loadMoreEvents(this.state.pageNumber);
          }
        );
      }
    }
  };
 



  render() {
    const { pastEvents, t,isAdmin, showLoadPast} = this.props;
    if (
      !pastEvents ||
      isUndefined(pastEvents) ||
      isNull(pastEvents)
    ) {
      return <div style={{width:"1246px", margin:'auto'}}>
        <SkeletonLoder width={"100%"} height={"60px"} style={{marginTop:"25px"}}/>
        <div width={"1246px"} height={"354px"} style={{margin:"auto", marginTop:'25px',display:'flex',gap:'25px'}}> 
          <div style={{display:'flex', gap:"25px",width:'836px'}}>
            <SkeletonLoder width={"399px"} height={"334px"}/>
            <SkeletonLoder width={"399px"} height={"334px"}/>
          </div>
          <div style={{width:'385px'}}>
            <SkeletonLoder width={"100%"} height={"21px"} style={{marginBottom:"15px"}}/>
            <SkeletonLoder width={"100%"} height={"140px"} style={{marginBottom:"15px"}}/>
            <SkeletonLoder width={"100%"} height={"60px"} style={{marginBottom:"10px"}}/>
            <SkeletonLoder width={"100%"} height={"60px"} style={{marginBottom:"10px"}}/>
            <SkeletonLoder width={"100%"} height={"60px"} style={{marginBottom:"10px"}}/>
            <SkeletonLoder width={"100%"} height={"60px"} style={{marginBottom:"10px"}}/>
          </div>
        </div>
      </div>;
    }
    return (
      <MainEventsContainer marginTop="1">
        <div style={{margin:"0px"}}>
          <CompletedChallengeWrapper
            overflow
            height
            onScroll={(e) => this.onScroll(e, pastEvents)}
          >
            <div className="challengeCard" style={{width:"100%",padding:"0px"}}>
              <EventsContainer>
                {!isAdmin ? (
                  pastEvents.length > 0 && pastEvents.some((event) => event.is_attended === 1) ? (
                    pastEvents
                      .filter((event) => event.is_attended === 1)
                      .map((event, index) => (
                        <SingleEvent
                          event={event}
                          history={this.props.history}
                          key={index}
                          length={index}
                          width={"calc((100% - 25px) / 3)"}
                          cards={3}
                          margin={"15px"}
                          view={"admin"}
              
                        />
                      ))
                  ) : (showLoadPast?null:
                    <NoComments>{t("Currently there are no events")}.</NoComments>
                  )
                ) : (
                  pastEvents.length > 0 ?
                    pastEvents.map((event, index) => (
                      <SingleEvent
                        event={event}
                        history={this.props.history}
                        key={index}
                        length={index}
                        width={"400px"}
                        cards={3}
                        margin={"15px"}
                        view={"admin"}
                      />
                    )) : (showLoadPast?null:
                      <NoComments>{t("Currently there are no events")}.</NoComments>
                    )
                )}
              </EventsContainer>
            </div>
          </CompletedChallengeWrapper>
        </div>
      </MainEventsContainer>
    );
  }
}

CompletedEvents.propTypes = {
  history: PropTypes.object.isRequired,
  pastEvents: PropTypes.array.isRequired,
  t: PropTypes.func,
  loadMoreEvents: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  role: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  showLoadPast: PropTypes.bool
};




export default connect(null,null)(withTranslation()(CompletedEvents));
