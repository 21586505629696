import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Layout, ChallengeHeading, Container, StyledLabel, StyledInputV2, InputContainer, UploadPhotoContainer, RemoveImageV2, InputImage, EditorContainer, ButtonContainer, CustomRadioButton } from "../../components/CreateEvent/style";
import HoverButton from "../common/HoverButton";
import StepsHeading from "../../components/CreateEvent/stepNames";
import { Arrows, LaunchEventIcon, NextArrows, UploadIcon, closeIcon } from "../../utils/icons";
import { checkImage, fetchApi, getOrientation, resetOrientation } from "../../utils/methods";
import { toast } from "react-toastify";
import { ImageUrl, imageErrorMessage } from "../../utils/constants";
import Editor from "../CKEditor";
import { Main } from "../AdminTeamCreation/styles";
import CommonButton from "../common/CommonButton/CommonButton";
import { connect } from "react-redux";
import { getOndemandCategory } from "../../redux/actions";
import { CreateCoach } from "../../redux/constants/apiConstants";

function EventCoachCreate(props) {
  const {history} = props;
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [imgSrc, setImgSrc] = useState('');
  const [imageName, setImageName] = useState('');
  const [about, setAbout] = useState('');
  const[classCategoryTypeValue, setClassCategoryTypeValue] = useState([]);
  const [selectedClass, setSelectedClass] = useState({
    name: '',
    classTypeKey: null,
  });
  const [educationSpecialization, setEducationSpecialization] = useState('');
  const[buttonStatus,setButtonStatus] = useState(false);


  useEffect(() => {
    props.fetchOndemandCategory();
    window.console.log("imageName", imageName)
  
  },[])


  const redirect = () => {
    history.push(`/company/events`);
  };

  const renderHeading = (title) => (
    <ChallengeHeading color={"#005c87"}>
      <div style={{marginRight:"12px"}}>
        <HoverButton
          title={"Close"}
          width="24px"
          height="24px"
          svgPath={closeIcon()}
          onClick={redirect}
          activeColor={"#007AB1"}
          fillOpacity={1}
        />
      </div>
      {title}
    </ChallengeHeading>
  );

  const onChangeProfileImage = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          
          const array = document
            .getElementById("event-upload-file")
            .value.split("\\");
          reader.onloadend = () => {
            setImgSrc(reader.result);
            setImageName(array[array.length - 1]);
            
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                setImgSrc(baseImage)
                setImageName(array[array.length - 1])
              });
            });
          };
        } else {
          toast.error("Please select image file less than 20MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById("event-upload-file").value = "";
      }
    }
  };

  const removePhoto = () => {
    setImgSrc('');
    setImageName('');
  }

  const updateStep = (stepValue) => {
    setStep(stepValue);
  }

  const onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    
    trimmedString.length === 0 || trimmedString.length === 7 || trimmedString == "<p></p><p></p>" ? setAbout('') : setAbout(newContent) ; 
  }

  const onSelectClass = (name, value, key) => {
    window.console.log("name", name);
    window.console.log("value", value);
    window.console.log("key", key);

    let updatedClassCategoryTypeValue = [...classCategoryTypeValue];

    if (updatedClassCategoryTypeValue.includes(value)) {
      updatedClassCategoryTypeValue = updatedClassCategoryTypeValue.filter((item) => item !== value);
    } else {
      if (updatedClassCategoryTypeValue.length <= 7) {
        updatedClassCategoryTypeValue.push(value);
      }
    }

    setClassCategoryTypeValue(updatedClassCategoryTypeValue);
    setSelectedClass({
      name: value.toString(),
      classTypeKey: key,
    });

    window.console.log("selectedClass",selectedClass)
  };

  const PostEvent = async()=>{

    const payload = {
      name:name,
      about_me:about,
      intrested_area_id:classCategoryTypeValue,
      education:educationSpecialization,
      profile_image:imgSrc
    };
    setButtonStatus(true);
    
    try{

      const res = await fetchApi(CreateCoach, "POST",payload);

      if(res.message){
        toast.error(res.message);
        setButtonStatus(false);
      }else{
        toast.success(res.data[0]);
        history.push({pathname:"/company/events"});
        setButtonStatus(false);
      }

    }catch(error){
      toast.error(error)

    }
  }

  const stepOne = () => (
    <Layout>
      <div className="main" >
        {renderHeading("Add New Coach")}

        <Container color="#005C87">
          <div className="stepsNameHighlight">
            <StepsHeading stepCount={step} type="create-coach" />
          </div>
          <div className="form">
            <div className="heading">
              <div className="step">
                {step}
              </div>
              <div className="headingName" style={{color: "#005c87"}}>
                {"Coach Introduction"}
              </div>
            </div>
            <div className="formBody">
              <InputContainer>
                <StyledLabel color={"#005c87"}>{"Coach Name"}{<span>*</span>}</StyledLabel>
                <StyledInputV2
                  placeholder={"Enter Name"}
                  name="title"
                  onChange={(e)=>{setName(e.target.value)}}
                  value={name}
                  maxLength="100"
                  style={{background:"white"}}
                  pColor={"#649bb3"}
                  color="#005c87"
                  border={"1px solid #afcbd3"}
                />
              </InputContainer>

              <StyledLabel color={"#005c87"}>{"Profile Image"}{<span>*</span>}</StyledLabel>
              <UploadPhotoContainer>
                <div className="innerDivImageContainer">
                  {imgSrc ? (
                    <RemoveImageV2 >
                      <img alt="profilre-img" src={imgSrc}></img>
                      <div className="main-div" onClick={removePhoto}>
                        <div className="icon-div" ><div className="sub-icon-div">{closeIcon("white","8","8")}</div></div>
                      </div>
                    </RemoveImageV2>
                  ) : (
                    <div className="uploadImage">

                      <InputImage
                        className="edit-profile-pic "
                        imageReload={1} 
                        style={{background:"unset",color:"#005c87"}}
                      >
                        <div className="middle-section">
                          <div>{UploadIcon()}</div>
                          <div className="title">{"Upload File"}</div>
                          <div className="sub-title">{".jpeg,.png"}</div>
                          
                        </div>
                        <input
                          id="event-upload-file"
                          type="file"
                          name="user"
                          accept=".jpeg, .png, .jpg"
                          multiple={false}
                          onChange={(e) => onChangeProfileImage(e)}
                          onClick={(e) =>
                            e.target.files[0] && onChangeProfileImage(e)
                          }
                        />
                      </InputImage>
                    </div>
                  )}
                </div>
              </UploadPhotoContainer>
              <InputContainer>
                <StyledLabel color={"#005c87"} > {"About the Coach"} <span>*</span></StyledLabel>
                <EditorContainer>
                  <Editor
                    content={about}
                    onChange={onChangeDescription}
                    placeholder={"Write bio here..."}
                  />
                </EditorContainer>
              </InputContainer>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )

  const stepTwo = () => (
    
    <Layout>
      <div className="main" >
        {renderHeading("Add New Coach")}

        <Container color="#005C87">
          <div className="stepsNameHighlight">
            <StepsHeading stepCount={step} type="create-coach" />
          </div>
          <div className="form">
            <div className="heading">
              <div className="step">
                {step}
              </div>
              <div className="headingName" style={{color: "#005c87"}}>
                {"Area of wellness expertise"}
              </div>
            </div>

            <div className="formBody">
              <InputContainer>
                <StyledLabel color={"#005c87"} >{"Select between 5 to 8 Category Tags"}{<span>*</span>}</StyledLabel>

                <div className="radioButtonClassCategories" >
                  {
                    props.ondemandCategory.map((category, index)=>(
                      <span key={category.id}>
                        <CustomRadioButton
                          onClick={() => {
                            onSelectClass(
                              "classCategoryTypeValue",
                              props.ondemandCategory[index].id,
                              props.ondemandCategory[index].name
                            );
                            // this.setState({
                            //   selectedOndemandCategory: props.ondemandCategory[index].name
                            // });
                          }}
                          background={"#85c0ea"}
                        >
                          { classCategoryTypeValue?.includes(props.ondemandCategory[index].id) && <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>}
                        </CustomRadioButton>
                        {category.name}
                      </span>
                    ))
                  }
                </div>

              </InputContainer>

              <InputContainer>
                <StyledLabel color={"#005c87"}>{"Education/Specialization"}{<span>*</span>}</StyledLabel>
                <StyledInputV2
                  placeholder={"Enter education"}
                  name="title"
                  onChange={(e)=>{setEducationSpecialization(e.target.value)}}
                  value={educationSpecialization}
                  maxLength="100"
                  style={{background:"white"}}
                  pColor={"#649bb3"}
                  color="#005c87"
                  border={"1px solid #afcbd3"}
                />
              </InputContainer>

              
              
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
  return (
    <React.Fragment>
      { step === 1 
        ? stepOne()
        : step === 2 && stepTwo()
      }
      { step === 1 ? (
        <ButtonContainer>
          <Main>
            <CommonButton 
              btnType={"squareIcon"}
              onClick={() => updateStep(2)}
              disabled={!name || !imgSrc || !about}
              title={"Next"}
              nextIcon={NextArrows("white")}
            />
          </Main>
        </ButtonContainer>
      ) : step === 2 && (
        <ButtonContainer>
          <div className="wrapper">
            <CommonButton
              btnType={"squareIcon"}
              styles={{color:"white",textColor:"#007AB1",border:"#007AB1", marginLeft:"0px", }}
              onClick={() => updateStep(1)}
              title={"Go Back"}
              icon={Arrows()}
            />
            <CommonButton
              btnType={"squareIcon"}
              disabled={!(classCategoryTypeValue.length>4&&classCategoryTypeValue.length<=8) || !educationSpecialization || buttonStatus}
              // disabled={buttonStatus}
              styles={{marginAuto:"auto",color:"#007AB1" }}
              onClick={() => PostEvent()}
              title={"Submit"}
              nextIcon={LaunchEventIcon()}
            />
          </div>
        </ButtonContainer>
      )
      }
    </React.Fragment>
  )
}

EventCoachCreate.propTypes = {
  history: PropTypes.object,
  parentRoute: PropTypes.string,
  allowedRoles: PropTypes.bool,
  userPermissions: PropTypes.array,
  fetchOndemandCategory: PropTypes.func,
  ondemandCategory: PropTypes.array,
}

const mapStateToProps = (state) => ({
  ondemandCategory: state.events.ondemandCategory,

})

const mapDispatchToProps = (dispatch) => ({
  fetchOndemandCategory: () => dispatch(getOndemandCategory()),

})

export default connect(mapStateToProps, mapDispatchToProps)(EventCoachCreate);
